const Grid = (props) => {

  return (
    <div className="grid">
      <div className="grid__lines grid__lines--horizontal">
        <div
          className="grid__line grid__line--horizontal"
          style={{
            top: '20px'
          }}
        />
        <div
          className="grid__line grid__line--horizontal"
          style={{
            top: '100px'
          }}
        />
      </div>
      <div className="grid__lines grid__lines--vertical">
      {
        Array(13).fill(0).map(
          (item, i) => (
            <div
              className="grid__line grid__line--vertical"
              key={i}
              style={{
                left: (100 / 12 * i) + '%'
              }}
            />
          )
        )
        }
      </div>
    </div>
  )
};

export default Grid;