import { useRef, useEffect } from 'react';

const getMouseOrTouchPosition = (event) => {
  const coordinates = { x: -1, y: -1 };
  if (event.clientX && event.clientY) {
    coordinates.x = event.clientX;
    coordinates.y = event.clientY;
  } else if (event.touches && event.touches[0]) {
    coordinates.x = event.touches[0].clientX;
    coordinates.y = event.touches[0].clientY;
  }

  return coordinates;
}

const Channel4LinkCanvas = (props) => {

  const canvas = useRef();
  const { color, menu, windowWidth, windowHeight, isActive } = props;

  useEffect(() => {
    if (canvas.current && menu.current) {
      if (isActive === true) {
        canvas.current.style.width = menu.current.offsetWidth + 'px';
        canvas.current.style.height = menu.current.offsetHeight + 'px';
      } else {
        canvas.current.style.width = menu.current.offsetWidth + 'px';
        canvas.current.style.height = menu.current.offsetHeight + 'px';
      }
    }
  }, [windowWidth, windowHeight, menu, isActive]);

  useEffect(() => {
    const rgb = {}

    if (color) {
      const colorArray = color.replace('rgb(', '').replace(')', '').split(', ');
      rgb.r = colorArray[0];
      rgb.g = colorArray[1];
      rgb.b = colorArray[2];
    }

    if (canvas.current && windowWidth && windowHeight) {
      canvas.current.width = menu.current.offsetWidth * window.devicePixelRatio;
      canvas.current.height = menu.current.offsetHeight * window.devicePixelRatio;
    }

    const drawOnCanvas = (x, y) => {
      if (canvas.current && rgb.r) {
        const ctx = canvas.current.getContext('2d');

        const radius = 60 * window.devicePixelRatio;

        const multiplierAmount = (canvas.current.width / canvas.current.offsetWidth);

        var grd = ctx.createRadialGradient(
          x * multiplierAmount - radius,
          y * multiplierAmount - radius,
          0,
          x * multiplierAmount - radius,
          y * multiplierAmount - radius,
          radius
        );
        grd.addColorStop(0, `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.1)`);
        grd.addColorStop(1, `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0)`);

        // Fill with gradient
        ctx.fillStyle = grd;
        ctx.fillRect(
          x * multiplierAmount - radius * 2,
          y * multiplierAmount - radius * 2,
          radius * 4,
          radius * 4
        );

      }
    }

    const handleMouseMove = (e) => {
      const coordinates = getMouseOrTouchPosition(e);
      const { x, y } = coordinates;

      if (canvas.current) {
        const offsetLeft = menu.current.offsetLeft;
        const offsetTop = menu.current.offsetTop;
        const offsetWidth = menu.current.offsetWidth;
        const offsetHeight = menu.current.offsetHeight;

        if (
          x >= offsetLeft && x <= offsetLeft + offsetWidth &&
          y >= offsetTop && y <= offsetTop + offsetHeight
        ) {
          if (window.location.href.indexOf('/channel-4') === window.location.href.length - 10) {
            drawOnCanvas(x - offsetLeft, y - offsetTop);
          }
        }
      }
    }

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('touchmove', handleMouseMove);
    window.addEventListener('touchstart', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('touchmove', handleMouseMove);
      window.removeEventListener('touchstart', handleMouseMove);
    }
  }, [color, menu, windowWidth, windowHeight]);

  return (
    <canvas
      ref={canvas}
      className={`channel-4__link__canvas${isActive === true ? ' active' : ''}`}
    />
  )
}

export default Channel4LinkCanvas;