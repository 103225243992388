import VideoPlayer from './VideoPlayer';
import { cockpitUrl } from '../utils/cockpit.js';
import parse from 'html-react-parser';
import AudioPlayer from './AudioPlayer';

const FlexibleContentLinks = (props) => {
  return (
    <div className="flexible-content__links">
      {
        props.value ?
          props.value[0] ?
            props.value.map(
              (item, i) => (
                <div className="file-link-col" key={i}>
                  <a className="flexible-content__link" href={cockpitUrl + '/' + item.value.url} download={item.value.download} rel="noreferrer" target="_blank">{item.value.text}</a>
                </div>
              )
            )
          : '' : ''
      }
    </div>
  )
}

const FlexibleContentImage = (props) => {
  return (
    props.value && (
      <img className="img-full-width flexible-content__img" src={cockpitUrl + '/' + props.value.path} alt={props.value.title ? props.value.title : 'image'} />
    )
  )
}

const FlexibleContentAudioPlayer = (props) => {

  const { audio } = props.value;

  return (
    <div>
      <AudioPlayer url={audio} />
    </div>
  )
}

const FlexibleContentVideoPlayer = (props) => {

  const { video, poster } = props.value;

  return (
    <div>
      <VideoPlayer url={video} poster={poster} {...props} />
    </div>
  )
}

const FlexibleContentText = (props) => {

  return (
    <div className="flexible-content__text__wrapper">
      {props.value ? parse(props.value) : ''}
    </div>
  )
}

const FlexibleContentBlock = (props) => {
  return (
    <div className="flexible-content">
      {props.field.name.toLowerCase() === 'text' ? <FlexibleContentText {...props} /> : '' }
      {props.field.name.toLowerCase() === 'video' ? <FlexibleContentVideoPlayer {...props} /> : '' }
      {props.field.name.toLowerCase() === 'audio' ? <FlexibleContentAudioPlayer {...props} /> : '' }
      {props.field.name.toLowerCase() === 'image' ? <FlexibleContentImage {...props} /> : '' }
      {props.field.name.toLowerCase() === 'links' ? <FlexibleContentLinks {...props} /> : '' }
    </div>
  )
}

export { FlexibleContentBlock };