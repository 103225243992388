import { AnimatePresence, motion } from 'framer-motion';
import { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import LazyloadImage from './LazyloadImage';
import FullscreenIcon from './icons/FullscreenIcon.js';
import * as _ from 'underscore';
import { durationInSecondsGetHours, durationInSecondsGetMinutes, durationInSecondsGetSeconds } from '../utils/misc/duration.js';
import TransformControls from './TransformControls';

const VideoPlayerFullScreen = (props) => {

  const { url, autoplay, muted, poster, title, titleWithLineBreaks, subtitle, noControls, wrapper, windowWidth, windowHeight, pageInner } = props;
  const [isPlaying, setIsPlaying] = useState(autoplay === true ? true : false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [videoUiIsHidden, setVideoUiIsHidden] = useState(false);
  const videoPlayerMiniInactivityTimeout = useRef();
  const [aspectRatio, setAspectRatio] = useState(1 / 0.5625);

  const playerWrapper = useRef();
  const playerOuter = useRef();
  const player = useRef();

  useEffect(() => {
    if (wrapper && pageInner.current && aspectRatio) {
      if (wrapper.current && playerWrapper.current && typeof windowWidth !== 'undefined' && typeof windowHeight !== 'undefined') {
        if ((pageInner.current.offsetWidth - 2) / (pageInner.current.offsetHeight - 2) > aspectRatio) {
          // container is more landscape than video
          playerWrapper.current.style.width = (pageInner.current.offsetHeight - 2) * aspectRatio - 2 + 'px';
          playerWrapper.current.style.height = pageInner.current.offsetHeight - 2 + 'px';

          if (isPlaying === true) {
            playerOuter.current.style.width = pageInner.current.offsetHeight * aspectRatio + 'px';
            playerOuter.current.style.height = pageInner.current.offsetHeight + 'px';
          } else {
            playerOuter.current.style.width = '100%';
            playerOuter.current.style.height = '100%';
          }
        } else {
          // container is more portrait than video
          playerWrapper.current.style.height = (pageInner.current.offsetWidth - 2) / aspectRatio - 2 + 'px';
          playerWrapper.current.style.width = pageInner.current.offsetWidth - 2 + 'px';

          if (isPlaying === true) {
            playerOuter.current.style.height = pageInner.current.offsetWidth / aspectRatio + 'px';
            playerOuter.current.style.width = pageInner.current.offsetWidth + 'px';
          } else {
            playerOuter.current.style.width = '100%';
            playerOuter.current.style.height = '100%';
          }
        }
      }
    }
  }, [wrapper, windowWidth, windowHeight, aspectRatio, isPlaying, pageInner]);

  const getCurrentTime = (e) => {
    const timeElapsed = e.playedSeconds;
    setCurrentTime(timeElapsed);
  }

  const getCurrentTimeThrottled = _.throttle(getCurrentTime, 120);

  const getDuration = (e) => {
    const durationInSeconds = e;
    setDuration(durationInSeconds);
  }

  const handleSeek = (e) => {
    player.current.seekTo(e.target.value);
  }

  const handleSeekThrottled = _.throttle(handleSeek, 120);

  const onInactivityTimeoutCompleted = () => {
    setVideoUiIsHidden(true);
  }

  const handleInteract = () => {
    clearTimeout(videoPlayerMiniInactivityTimeout.current);
    setVideoUiIsHidden(false);
    videoPlayerMiniInactivityTimeout.current = setTimeout(onInactivityTimeoutCompleted, 3000);
  }

  const handleFullScreen = () => {
    if (player.current?.getInternalPlayer()) {
      if (player.current?.getInternalPlayer().requestFullscreen) {
        player.current?.getInternalPlayer().requestFullscreen();
      } else if (player.current?.getInternalPlayer().webkitRequestFullscreen) { /* Safari */
        player.current?.getInternalPlayer().webkitRequestFullscreen();
      } else if (player.current?.getInternalPlayer().msRequestFullscreen) { /* IE11 */
        player.current?.getInternalPlayer().msRequestFullscreen();
      }
    }
  }

  return (
    <div
      className="video-player--fullscreen"
      onClick={(e) => {
        handleInteract(e);
      }}
      ref={playerOuter}
      onTouchStart={handleInteract}
      onMouseMove={handleInteract}
    >
      <TransformControls />
      <AnimatePresence>
        {
          poster && poster.path && isPlaying === false &&
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: .4,
              delay: isPlaying === true ? .4 : 0
            }}
              className="video-player__poster"
            >
            <LazyloadImage
                className="video-player__poster__img"
                isBackgroundImage={true}
              alt={poster.title}
              image={poster}
            />
          </motion.div>
        }
      </AnimatePresence>
      <div
        className="video__wrapper--fullscreen"
        ref={playerWrapper}
        onClick={(e) => {
          setIsPlaying(!isPlaying);
        }}
      >
        <button
          className="button--fullscreen"
          aria-label="view fullscreen"
          onClick={handleFullScreen}
        >
        <FullscreenIcon />
        </button>
          <ReactPlayer
            ref={player}
            url={url}
            className="video-player__player"
            playsinline={true}
            playing={isPlaying}
            volume={1}
            muted={muted ? muted : false}
            height={'100%'}
            width={'100%'}
            loop={true}
            onCanPlay={(e) => {
              if (url.indexOf('storage') === 0) {
                if (player.current.getInternalPlayer() && player.current.getInternalPlayer().videoWidth) {
                  setAspectRatio(player.current.getInternalPlayer().videoWidth / player.current.getInternalPlayer().videoHeight);
                }
              }
            }}
            onPlay={() => { setIsPlaying(true) }}
            onPause={() => { setIsPlaying(false) }}
            onProgress={(e) => {
              getCurrentTimeThrottled(e)
            }}
            onSeek={(e) => {
              getCurrentTimeThrottled(e)
            }}
            onDuration={(e) => {
              getDuration(e)
            }}
          />
      </div>
      {
        noControls !== true &&
        <div className={`video-player__controls`}>
          <AnimatePresence>
            {
              (videoUiIsHidden === false || isPlaying === false) &&
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: .2 }}
                  className="video-player__controls__inner"
                >
                  <div className="video-player__time__wrapper">
                    <p className="video-player__time video-player__time--elapsed">
                      {durationInSecondsGetHours(currentTime)} : {durationInSecondsGetMinutes(currentTime)} : {durationInSecondsGetSeconds(currentTime)}
                    </p>
                    <button
                      className={`video-player__button--play-pause ${isPlaying === false ? 'play' : 'pause'}`}
                      onClick={() => {setIsPlaying(!isPlaying)}}
                    />
                    <div className="video-player__input--time__wrapper">
                      <input
                        type="range"
                        className="video-player__input--time"
                        name="time"
                        max={duration}
                        value={currentTime}
                        onChange={(e) => {
                          setIsPlaying(false);
                          setCurrentTime(e.target.value);
                          handleSeekThrottled(e);
                        }}
                        onMouseDown={() => {
                          setIsPlaying(false);
                        }}
                        onMouseUp={() => {
                          setIsPlaying(true);
                        }}
                      />
                  </div>
                </div>
              </motion.div>
            }
          </AnimatePresence>
        </div>
      }
      {
        noControls === true &&
        isPlaying === false &&
        <motion.button
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="video-player__button--play-pause play video-player__button--play-pause--no-controls"
          onClick={() => setIsPlaying(true)}
        />
      }
      <AnimatePresence>
        {
          (title || subtitle) && isPlaying === false &&
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="video-player__title-and-subtitle__wrapper"
            onClick={(e) => {
              setIsPlaying(!isPlaying);
            }}
          >
            <h2 className="video-player__title font--universalSans">
              {
                titleWithLineBreaks ?
                titleWithLineBreaks[0] ?
                titleWithLineBreaks.map(
                  (line, i) => (
                    <span className="menu--home__item__title__span__line" key={i}>{line}</span>
                  )
                )
                :
                <span>{title}</span>
                :
                <span>{title}</span>
              }
            </h2>
            {
              subtitle &&
              <p className="video-player__subtitle font--gtPressuraMono">
                <span>{subtitle}</span>
              </p>
            }
          </motion.div>
        }
      </AnimatePresence>
    </div>
  )
}

export default VideoPlayerFullScreen;