import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import VideoPlayerFullScreen from '../components/VideoPlayerFullScreen';
import SkipHandwritten from '../components/icons/handwrittenElements/SkipHandwritten';

const SkipButton = (props) => {

  const [isHovered, setIsHovered] = useState(false);
  const [textAppearanceVisible, setTextAppearanceVisible] = useState(true);

  return (
    <Link
      to="/home"
      className="skip-button handwritten-button uppercase"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onBlur={() => setIsHovered(false)}
    >
      <div className="handwritten-button__inner">
        <span className={`skip-button__text handwritten-button__text${textAppearanceVisible === false ? ' hidden' : ''}`}>Skip</span>
        <SkipHandwritten
          isHovered={isHovered}
          setTextAppearanceVisible={setTextAppearanceVisible}
        />
      </div>
    </Link>
  )
}

const LandingPage = (props) => {

  const { landingPageData } = props;

  const pageInner = useRef();
  const wrapper = useRef();

  return (
    <div
      className="page"
    >
      <div
        className="page__inner no-border"
        ref={pageInner}
      >
        <div
          className="page__video--full__wrapper"
          ref={wrapper}
        >
          {
            landingPageData.video && landingPageData.video !== '' &&
            <VideoPlayerFullScreen
              url={landingPageData.video}
              title={landingPageData.title}
              subtitle={landingPageData.subtitle}
              noControls={true}
              wrapper={wrapper}
              pageInner={pageInner}
              autoplay={true}
              poster={landingPageData.videoPoster}
              {...props}
            />
          }
          <SkipButton {...props} />
        </div>
      </div>
    </div>
  )
}

export default LandingPage;