import Handwritten from './Handwritten';

const FourHandwritten = (props) => {

  return (
    <Handwritten
      viewBox="0 0 86.79 106.47"
      id="four"
      {...props}
    >
      <path d="M15.83,5S8.06,13.16,8.06,23.21,5,35.87,5,42.42V61.63S32.07,53.77,41.24,52s29.2-4.29,40.55-3"/>
      <path d="M51.63,5.36s-5,12.07-8,17.85S43,39.85,40.37,40.67,46.83,69.49,46,74.73s.07,18.49-2.35,26.74"/>
    </Handwritten>

  )
}

export default FourHandwritten;