import { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/Selfridges_Only_Black_White_CS4-01.png';
import MuteHandwritten from './icons/handwrittenElements/MuteHandwritten';
import SiteTitleHandwritten from './icons/handwrittenElements/SiteTitleHandwritten';
import SiteTitleMobileHandwritten from './icons/handwrittenElements/SiteTitleMobileHandwritten';
import { cockpitUrl } from '../utils/cockpit';
import One from './icons/handwrittenElements/One';
import Two from './icons/handwrittenElements/Two';
import Three from './icons/handwrittenElements/Three';
import Four from './icons/handwrittenElements/Four';

const MenuButton = ({ menuIsActive, setMenuIsActive }) => (
  <button
    className="menu-button"
    onClick={() => setMenuIsActive(!menuIsActive)}
    aria-label={`${menuIsActive === true ? 'Close' : 'Open'} menu`}
  >
    {
      Array(3).fill('').map(
        (item, i) => (
          <div
            className="menu-button__inner"
            key={i}
          />
        )
      )
    }
  </button>
);

const SiteTitle = (props) => {

  const { portrait } = props;
  const [isHovered, setIsHovered] = useState(false);
  const [textAppearanceVisible, setTextAppearanceVisible] = useState(true);

  return (
    <h1 className="site-title font--universalSans uppercase handwritten-button">
      <Link
        to="/Home"
        onMouseEnter={(e) => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onBlur={() => setIsHovered(false)}
        className="handwritten-button__inner"
      >
        <span className={`handwritten-button__text${textAppearanceVisible === false ? ' hidden' : ''}`}>Open House {new Date().getFullYear()}</span>
        {
          portrait === true ?
            <SiteTitleMobileHandwritten
              isHovered={isHovered}
              setTextAppearanceVisible={setTextAppearanceVisible}
              {...props}
            />
            :
          <SiteTitleHandwritten
            isHovered={isHovered}
            setTextAppearanceVisible={setTextAppearanceVisible}
            {...props}
          />
        }
      </Link>
    </h1>
  )
}

const SiteLogo = () => (
  <div className="site-logo__wrapper">
    <img src={logo} alt="Selfridges & Co. Logo" className="site-logo" />
  </div>
);

const MenuMainLinkHandwrittenElement = (props) => {

  const { number } = props;

  switch (number) {
    case 1:
      return <One {...props} />
    case 2:
      return <Two {...props} />
    case 3:
      return <Three {...props} />
    case 4:
      return <Four {...props} />
    default:
      return null;
  }
}

const MenuMainLink = (props) => {

  const { number, location, hoveredHeaderItem, sethoveredHeaderItem, channel1Data, channel2Data, channel3Data, strategyData } = props;
  const [isHovered, setIsHovered] = useState(false);
  const [textAppearanceVisible, setTextAppearanceVisible] = useState(true);
  const [hoveredHeaderItemContent, sethoveredHeaderItemContent] = useState(number === 1 ? channel1Data.title : number === 2 ? channel2Data.title : number === 3 ? channel3Data.title : strategyData.title);

  useEffect(() => {
    sethoveredHeaderItemContent(number === 1 ? channel1Data.title : number === 2 ? channel2Data.title : number === 3 ? channel3Data.title : strategyData.title);
  }, [number, channel1Data.title, channel2Data.title, channel3Data.title, strategyData.title]);

  return (
    <Link
      className={`menu--main__link handwritten-button${props.location.pathname === `/channel-${number}` ? ' active' : ''}`}
      to={`/channel-${number}`}
      title={`Channel ${number}`}
      onMouseEnter={(e) => {
        setIsHovered(true);
        sethoveredHeaderItem({
          text: hoveredHeaderItemContent,
          top: e.target.offsetTop + 12
        });
      }}
      onMouseLeave={() => {
        setIsHovered(false);
        if (hoveredHeaderItem.text === hoveredHeaderItemContent) {
          sethoveredHeaderItem({});
        }
      }}
      onBlur={() => {
        setIsHovered(false);
        if (hoveredHeaderItem.text === hoveredHeaderItemContent) {
          sethoveredHeaderItem({});
        }
      }}
    >
      <div className="handwritten-button__inner">
        <span className={`menu--main__link__inner handwritten-button__text${textAppearanceVisible === false ? ' hidden' : ''}`}>{number}</span>
        <MenuMainLinkHandwrittenElement
          {...props}
          isHovered={location.pathname !== `/channel-${number}` ? isHovered : true}
          setTextAppearanceVisible={setTextAppearanceVisible}
          number={number}
        />
      </div>
    </Link>
  )
}

const MenuMain = (props) => {
  return (
    <nav className="header__menu header__menu--main">
      {
        Array(4).fill('').map(
          (item, i) => (
            <MenuMainLink
              key={i}
              number={i + 1}
              {...props}
            />
          )
        )
      }
    </nav>
  )
}

const MenuSecondary = (props) => {

  const { hoveredHeaderItem, sethoveredHeaderItem } = props;

  return (
    <nav className="header__menu header__menu--secondary">
      <Link
        className={`menu--secondary__link${props.location.pathname === '/channel-5' ? ' active' : ''}`}
        to={'/channel-5'}
        title={props.channel5Data.title}
        onMouseEnter={(e) => {
          sethoveredHeaderItem({
            text: props.channel5Data.title,
            top: e.target.offsetTop - 3
          });
        }}
        onMouseLeave={(e) => {
          if (hoveredHeaderItem.text === props.channel5Data.title) {
            sethoveredHeaderItem({});
          }
        }}
        onBlur={(e) => {
          if (hoveredHeaderItem.text === props.channel5Data.title) {
            sethoveredHeaderItem({});
          }
        }}
      ></Link>
      <a
        className="menu--secondary__link"
        download
        rel="noreferrer"
        target="_blank"
        onMouseEnter={(e) => {
          sethoveredHeaderItem({
            text: 'Download Our Strategy',
            top: e.target.offsetTop - 3
          });
        }}
        onMouseLeave={(e) => {
          if (hoveredHeaderItem.text === 'Download Our Strategy') {
            sethoveredHeaderItem({});
          }
        }}
        onBlur={(e) => {
          if (hoveredHeaderItem.text === 'Download Our Strategy') {
            sethoveredHeaderItem({});
          }
        }}
        href={props.strategyData.strategy_download_link ? cockpitUrl + '/' + props.strategyData.strategy_download_link.replace('cockpit/', '') : ''}
      > </a>
      <a
        target="_blank"
        rel="noreferrer"
        className="menu--secondary__link"
        href={`mailto:${props.email}`}
        onMouseEnter={(e) => {
          sethoveredHeaderItem({
            text: 'Share your thoughts',
            top: e.target.offsetTop - 3
          });
        }}
        onMouseLeave={(e) => {
          if (hoveredHeaderItem.text === 'Share your thoughts') {
            sethoveredHeaderItem({});
          }
        }}
        onBlur={(e) => {
          if (hoveredHeaderItem.text === 'Share your thoughts') {
            sethoveredHeaderItem({});
          }
        }}
      > </a>
      <Link
        className={`menu--secondary__link${props.location.pathname === '/channel-8' ? ' active' : ''}`}
        to={'/channel-8'}
        title="Small Print"
        onMouseEnter={(e) => {
          sethoveredHeaderItem({
            text: 'The Small Print',
            top: e.target.offsetTop - 3
          });
        }}
        onMouseLeave={(e) => {
          if (hoveredHeaderItem.text === 'The Small Print') {
            sethoveredHeaderItem({});
          }
        }}
        onBlur={(e) => {
          if (hoveredHeaderItem.text === 'The Small Print') {
            sethoveredHeaderItem({});
          }
        }}
      ></Link>
    </nav>
  )
}

const MuteButton = (props) => {

  const { isMuted, setIsMuted } = props;
  const [isHovered, setIsHovered] = useState(false);
  const [textAppearanceVisible, setTextAppearanceVisible] = useState(true);

  return (
    <button
      className={`mute-button handwritten-button uppercase${isMuted === true ? ' active' : ''}`}
      onMouseEnter={(e) => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onBlur={() => setIsHovered(false)}
      onClick={() => {
        setIsMuted(!isMuted);
      }}
    >
      <div className="handwritten-button__inner">
        <span className={`mute-button__text handwritten-button__text${textAppearanceVisible === false ? ' hidden' : ''}`}>Mute</span>
        <MuteHandwritten
          isHovered={isHovered}
          setTextAppearanceVisible={setTextAppearanceVisible}
          {...props}
        />
      </div>
    </button>
  )
}

const HeaderPortrait = (props) => {

  const { windowHeight } = props;

  return (
    <Fragment>
      <header>
        <MenuButton {...props} />
        <SiteLogo />
        <SiteTitle {...props} portrait={true} />
      </header>
      <MenuMain {...props} />
      <MuteButton {...props} />
      <div
        className="header__border"
        style={{
          height: windowHeight - 20 + 'px'
        }}
      />
    </Fragment>
  )
}

const HoveredMarker = (props) => {

  const { hoveredHeaderItem } = props;

  if (hoveredHeaderItem.text && typeof hoveredHeaderItem.top !== 'undefined') {
    return (
      <p className="menu__hovered-marker font--gtPressuraMono"
        style={{ top: hoveredHeaderItem.top + 'px' }}
      >
        <span className="menu__hovered-marker__text">{hoveredHeaderItem.text}</span>
        <svg className="menu__hovered-marker__svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 507.5 10.8">
          <circle cx="5.4" cy="5.4" r="5.4" /><circle cx="48.59" cy="5.4" r="5.4" /><circle cx="26.99" cy="5.4" r="5.4" /><circle cx="70.19" cy="5.4" r="5.4" /><circle cx="113.38" cy="5.4" r="5.4" /><circle cx="91.78" cy="5.4" r="5.4" /><circle cx="134.97" cy="5.4" r="5.4" /><circle cx="178.16" cy="5.4" r="5.4" /><circle cx="156.57" cy="5.4" r="5.4" /><circle cx="199.76" cy="5.4" r="5.4" /><circle cx="242.95" cy="5.4" r="5.4" /><circle cx="221.36" cy="5.4" r="5.4" /><circle cx="264.55" cy="5.4" r="5.4" /><circle cx="307.74" cy="5.4" r="5.4" /><circle cx="286.14" cy="5.4" r="5.4" /><circle cx="329.33" cy="5.4" r="5.4" /><circle cx="372.53" cy="5.4" r="5.4" /><circle cx="350.93" cy="5.4" r="5.4" /><circle cx="394.12" cy="5.4" r="5.4" /><circle cx="437.31" cy="5.4" r="5.4" /><circle cx="415.72" cy="5.4" r="5.4" /><circle cx="458.91" cy="5.4" r="5.4" /><circle cx="502.1" cy="5.4" r="5.4" /><circle cx="480.5" cy="5.4" r="5.4" />
        </svg>
      </p>
    );
  } else {
    return null;
  }
}

const HeaderLandscapeMobile = (props) => {

  return (
    <header
      className="font--universalSans uppercase"
    >
      <MenuButton {...props} />
      <SiteTitle {...props} />
      <SiteLogo />
      <MenuMain {...props} />
      <MuteButton {...props} />
    </header>
  )
}

const HeaderLandscape = (props) => {

  return (
    <Fragment>
      <HoveredMarker {...props} />
      <header className="font--universalSans uppercase">
        <SiteTitle {...props} />
        <SiteLogo />
        <MenuMain {...props} />
        <MenuSecondary {...props} />
        <MuteButton {...props} />
      </header>
    </Fragment>
  )
}

const Header = (props) => {

  const { hoveredHeaderItem, sethoveredHeaderItem } = props;
  const { windowWidth, windowHeight } = props;

  if (windowWidth > windowHeight) {
    if (windowWidth >= 768) {
      return (
        <HeaderLandscape {...props} hoveredHeaderItem={hoveredHeaderItem} sethoveredHeaderItem={sethoveredHeaderItem} />
      );
    } else {
      return (
        <HeaderLandscapeMobile {...props} hoveredHeaderItem={hoveredHeaderItem} sethoveredHeaderItem={sethoveredHeaderItem} />
      );
    }
  } else {
    return (
      <HeaderPortrait {...props} hoveredHeaderItem={hoveredHeaderItem} sethoveredHeaderItem={sethoveredHeaderItem} />
    );
  }
}

export default Header;