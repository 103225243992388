const TransformControls = () => (
  <div className="transform-controls">
    <div className="transform-controls__inner">
      {
        Array(8).fill(0).map(
          (item, i) => (
            <div className="transform-control" key={i} />
          )
        )
      }
    </div>
  </div>
);

export default TransformControls;