import Handwritten from './Handwritten';

const SkipHandwritten = (props) => {

  return (
    <Handwritten
      viewBox="0 0 288.49 134.06"
      id="skip"
      {...props}
    >
      <path d="M68.43,36.22S44.3,7.1,38.21,9.39,20.91,11.87,24,31.2s13.8,22.37,18.08,26.18,7.14,5.92,11.42,7S68.78,85.24,68.43,93.07,55.52,128.34,46,127.12,27.59,112.44,23.5,108.3C17.37,102.11,5,97,5,97"/>
      <path d="M118.26,114s-4-38.81-3.91-49.63-5.13-31.75,3.91-52.21"/>
      <path d="M153.34,15.62a41.51,41.51,0,0,0-19.85,13.71C125.05,40,114.27,60.48,114.35,64.38S132.5,75,141.32,84s31.09,32.81,30.9,33.18"/>
      <path d="M192.3,5c-1.23,2.06,4.2,6.47,2,17.29s-5.57,30.81-5.57,36.52,2.31,9.51,3.06,20,5,25.22,5,29,3,9.68,4.1,15.07"/>
      <path d="M242,12.17c0,9.52-1.9,20.46,0,30.46s6.55,52.34,4.7,59.48-1.1,17.25-4.8,27"/>
      <path d="M231.94,15.62c.55-1.28,14.68-4.88,26.4-3.45s21.24,3.58,23.15,10.12,3.33,18.43,0,20.81-22,9-31.48,10-6.86-3-6.86-3"/>
    </Handwritten>
  )
}

export default SkipHandwritten;