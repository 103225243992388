import Handwritten from './Handwritten';

const SiteTitleHandwritten = (props) => {

  return (
    <Handwritten
      viewBox="0 0 430.84 411.63"
      id="siteTitle"
      {...props}
    >
      <path d="M101.7,20.1c0,0,13.7,15.9,11,38.4s-1.2,41.1-6.6,47.6s-20.8,7.2-28,7.2s-21.6,3.5-23.8-20.8s1.8-32,2.8-42
        c2.2-22.9,10.3-32.2,20.3-34.8c9.4-2.4,29,5.6,32.2,6.5"/>
      <path d="M159.4,5c0,0-8.2,7.6-8.2,12.9s4.5,21.7,4.1,32.6s-6.7,31.4-6.7,40.4s-4.6,27.4-4.6,27.4"/>
      <path d="M151.7,8.5c0,0,5.5-1.5,14.5,0s15,2.6,17.6,4.5s6.4,8.2,7.1,14.6s4.1,16.9,0,19.1s-26.2,0-29.6,0c-3.2,0.1-6.4,0.5-9.6,1"/>
      <path d="M268.8,13.4c-5.4,1.5-7.6,2.8-13.2,2.5c-5.6-0.3-25.9-2.6-28.1-2.5s-4.5,6.7-4.5,12.3s5.2,23.2,4.5,35.9S223.8,80,223,84.5
        s0,29,0,29s10.1,0.6,15.7-1.7s11.6-5.2,16.5-6.4c4.9-1.1,6.8,0.6,6.8,0.6"/>
      <path d="M229.4,56.8c4.1,0.4,15.7,4.9,19.1,4.9c1.7,0.1,3.4-0.2,5.1-0.8"/>
      <path d="M307.5,119.7c0,0,1.2-27,2-32.6c1.2-9.5,1.2-19.1,0-28.6c-1.4-12.6-7-29.3-7-33.9c-0.1-11.2,6.5-14.2,6.5-14.2
        s13.9,28.9,22.8,37.3s16,25.2,17.9,31.6s2.6,20.1,3.7,28.1s-1,11.3-1,11.3s4.7-21.8,5.1-33.1s-1.1-32.2,0-42.7s3-28,0.4-37.9"/>
      <path d="M21.5,149.4c-1.4,3.6-8.1,12.2-8.1,21s8,37.7,8.1,43.7s-0.1,23.2,0,30.3s2.8,19,2.8,19"/>
      <path d="M54.3,149.4c0,0,5.5-4.6,5.8,2.5s3.8,43.4,3.8,46.4s-1.9,42.3-1.9,46.8s3.9,11.5,3.9,11.5"/>
      <path d="M72.9,208.5c-3.6,0.2-17-1.2-24.7-2s-11.6,3.9-18,3.9S13.8,213.1,5,209"/>
      <path d="M119.9,153.5c3.1,1.4,26,9.2,29.7,14.8s12.4,74,9.7,79.5s-5.4,11.4-16.6,11.4s-23.8-7.8-26.3-14.2s-4.7-16.5-3.6-21.7
        s1.9-47.2,5.2-50.2s9.3-7.1,13.1-11.6"/>
      <path d="M261.1,149.4c3.9,5.9,10.4,20.1,7.2,28.9s-4.1,32.7-10.5,42.4s-26.7,32.6-35,33.7s-12.8-22.1-14.3-31.1s-4.5-22.5-3-34.8
        s1.5-15.4,1.5-18.2s-1.9-8.5,0.4-16.8"/>
      <path d="M332.7,159.5c-7.8-2.8-19.5-9.7-24.9-8.1s-5,45.4-2.8,50.9s30.5,23.5,29.6,36.5S314,254.5,314,254.5s-9.4,2.7-15.2,2.1
        c-5.8-0.6-16.1-14.6-16.1-14.6"/>
      <path d="M407.6,157.5c-2.8,1-5.7,1.6-8.6,1.9c-4.5,0.4-27.7,2.2-30.3,6s1.5,22.4,6.7,38.6s2.6,48.6,4.1,52.8
        c1.5,4.1,8.2,2.6,20.2,2.6s19-3.2,26.1,0.3"/>
      <path d="M375,214.4c0,0,3.4-4.6,7.5-6.4s13.9-2,19.5,0s5.2,2.5,5.2,2.5"/>
      <path d="M56.5,334.9c0,0,1.8-7.8,3.7-11.5s3.8-10.1,9.4-13.1s8.1-2.2,12.1-2.2s12.2-0.1,12.2-0.1s4.1,4.4,3.8,13.5s1.5,13.2-2.2,18
        s-15.4,12-17.2,19.1S74.4,371.6,74,378s-4.5,19.1-1.9,21.3s6.7-1,16.1-0.1s13,3.5,17,7.5"/>
      <path d="M183,311c4.1,3,15.9,8.2,18.8,22.1s5.9,18.5,5.5,24.2s-10.1,28.2-16.5,30.8s-14.6,0.8-18.4,3s-7.1,5.2-10.9,4.1
        s-7.8-8.2-6.9-18s-2.1-14.2-1.8-18.4s-6-13.5-3-24s8.2-21.9,18-27s11.6-0.4,17.8-1.7"/>
      <path d="M233.7,333.7c0,0-0.6-4.4,2-10.4s4.9-9.1,8.6-15.4s7.1-11.9,14.2-10.8c4.1,0.6,7.8,2.6,10.5,5.6c0,0,5.3,12,0.6,24.7
        s-17.1,27.7-20.1,41.9c-1.8,8.6-2.4,17.3-1.9,26.1c0,0,5.6-2,10.5,0c4.9,2,20.2,0.8,28.4-3.1"/>
      <path class="st0" d="M309.2,316c0,0,4.8-14,13.7-16.7c11.4-3.5,34.2,6.3,28.5,29.9c-2.1,8.5-18,16-18,16S352,347,352,364.7
        c0,23.6-10.8,34.1-22.2,28.5c-11.4-5.6-20.5-23.6-20.5-23.6"/>
    </Handwritten>

  )
}

export default SiteTitleHandwritten;