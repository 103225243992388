import Handwritten from './Handwritten';

const SiteTitleHandwritten = (props) => {

  return (
    <Handwritten
      viewBox="0 0 1226.15 131.88"
      id="siteTitle"
      {...props}
    >
      <path class="st0" d="M53,27.3c0,0,13.7,15.9,11,38.4s-1.2,41.1-6.6,47.6s-20.8,7.2-28,7.2S7.9,124,5.7,99.7s1.8-32,2.8-42
        c2.3-22.9,10.3-32.2,20.4-34.8c9.4-2.4,29.1,5.6,32.2,6.5"/>
      <path class="st0" d="M110.7,12.2c0,0-8.2,7.6-8.2,12.9s4.5,21.7,4.1,32.6S99.9,89.1,99.9,98s-4.7,27.5-4.7,27.5"/>
      <path class="st0" d="M103,15.7c0,0,5.5-1.5,14.5,0s15,2.6,17.6,4.5s6.4,8.2,7.1,14.6s4.1,16.9,0,19.1s-26.2,0-29.6,0
        c-3.2,0.1-6.4,0.5-9.6,1"/>
      <path class="st0" d="M220.1,20.6c-5.4,1.5-7.6,2.8-13.2,2.5s-25.9-2.6-28.1-2.5s-4.5,6.7-4.5,12.3s5.2,23.2,4.5,35.9
        s-3.7,18.4-4.5,22.8s0,29,0,29s10.1,0.6,15.7-1.6s11.6-5.2,16.5-6.4s6.8,0.6,6.8,0.6"/>
      <path class="st0" d="M180.7,64c4.1,0.4,15.7,4.9,19.1,4.9c1.7,0.1,3.4-0.2,5-0.8"/>
      <path class="st0" d="M258.8,126.9c0,0,1.2-27,2-32.6c1.2-9.5,1.2-19.1,0-28.6c-1.4-12.6-7-29.3-7-33.9c-0.1-11.2,6.5-14.2,6.5-14.2
        s13.9,28.9,22.8,37.2s16,25.2,17.9,31.6s2.6,20.1,3.7,28.1s-1,11.4-1,11.4s4.7-21.8,5.1-33.1s-1.1-32.2,0-42.7s3-28,0.4-37.9"/>
      <path class="st0" d="M432.2,6.7c-1.4,3.6-8.1,12.2-8.1,21s8,37.7,8.1,43.7s-0.1,23.2,0,30.3s2.8,19,2.8,19"/>
      <path class="st0" d="M465,6.7c0,0,5.5-4.6,5.8,2.5s3.8,43.4,3.8,46.4s-1.9,42.3-1.9,46.8s3.9,11.5,3.9,11.5"/>
      <path class="st0" d="M530.6,10.8c3.1,1.4,26,9.2,29.7,14.8s12.3,74,9.7,79.5s-5.4,11.4-16.6,11.4s-23.8-7.8-26.3-14.2
        s-4.7-16.5-3.6-21.7s1.9-47.2,5.2-50.2s9.4-7.1,13.1-11.6"/>
      <path class="st0" d="M671.7,6.7c3.9,5.9,10.4,20.1,7.2,28.9s-4.1,32.7-10.5,42.4s-26.7,32.6-35,33.7S620.5,89.7,619,80.7
        s-4.5-22.5-3-34.8s1.5-15.4,1.5-18.2s-1.9-8.5,0.4-16.9"/>
      <path class="st0" d="M743.3,16.8c-7.8-2.7-19.5-9.7-24.9-8.1s-5,45.4-2.8,50.9S746.1,83,745.2,96s-20.6,15.8-20.6,15.8
        s-9.4,2.7-15.2,2.1s-16.1-14.5-16.1-14.5"/>
      <path class="st0" d="M818.2,14.8c-2.8,1-5.7,1.6-8.6,1.9c-4.5,0.4-27.7,2.2-30.3,6s1.5,22.4,6.7,38.5s2.6,48.6,4.1,52.8
        s8.2,2.6,20.2,2.6s19-3.2,26.1,0.3"/>
      <path class="st0" d="M785.6,71.7c0,0,3.4-4.6,7.5-6.4s13.8-1.9,19.5,0s5.2,2.5,5.2,2.5"/>
      <path class="st0" d="M912.5,43.1c0,0,1.8-7.8,3.7-11.6s3.8-10.1,9.4-13.1s8.2-2.2,12.1-2.2s12.2-0.1,12.2-0.1s4.1,4.4,3.8,13.5
        s1.5,13.2-2.2,18s-15.4,12-17.2,19.1s-3.7,13.1-4.1,19.5s-4.5,19.1-1.9,21.3s6.7-1,16.1-0.1s13,3.6,17,7.4"/>
      <path class="st0" d="M1039,19.2c4.1,3,15.9,8.2,18.8,22.1c2.9,13.9,5.9,18.5,5.5,24.2c-0.4,5.7-10.1,28.2-16.5,30.8
        c-6.4,2.6-14.6,0.8-18.3,3s-7.1,5.2-10.9,4.1s-7.8-8.2-6.9-18s-2.1-14.2-1.7-18.3s-6-13.5-3-24s8.2-21.9,18-27
        c9.8-5.1,11.6-0.4,17.8-1.7"/>
      <path class="st0" d="M1089.8,41.9c0,0-0.6-4.4,2.1-10.4c2.6-6,4.9-9.1,8.6-15.4s7.1-11.9,14.2-10.8c4.1,0.6,7.8,2.6,10.5,5.6
        c0,0,5.3,12,0.6,24.7s-17.1,27.7-20.1,41.9c-1.8,8.6-2.4,17.3-1.9,26.1c0,0,5.6-2,10.5,0c4.9,2,20.2,0.8,28.4-3.1"/>
      <path class="st0" d="M483.5,65.8c-3.6,0.2-17-1.2-24.7-2s-11.6,3.9-18,3.9s-16.3,2.6-25.1-1.5"/>
      <path class="st1" d="M1151.2,28.6c0,0,4.8-14,13.7-16.7c11.4-3.5,34.2,6.3,28.5,29.9c-2.1,8.5-18,16-18,16s18.5,1.7,18.5,19.5
        c0,23.6-10.8,34.1-22.2,28.5c-11.4-5.6-20.5-23.6-20.5-23.6"/>
    </Handwritten>
  )
}

export default SiteTitleHandwritten;