import Handwritten from './Handwritten';

const OneHandwritten = (props) => {

  return (
    <Handwritten
      viewBox="0 0 58.4 104.86"
      id="one"
      {...props}
    >
      <path d="M5,57S14.56,50.15,19.17,46c10-9.1,27.31-35.69,32.26-40.62s-1.14,43.27,0,50.1S48.77,76,50.29,82.78s1.28,12.91,3.11,17.08"/>
    </Handwritten>

  )
}

export default OneHandwritten;