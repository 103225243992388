import { AnimatePresence, motion } from 'framer-motion';
import { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { getImage } from '../utils/cockpit';
import FullscreenIcon from './icons/FullscreenIcon.js';
import * as _ from 'underscore';
import { durationInSecondsGetHours, durationInSecondsGetMinutes, durationInSecondsGetSeconds } from '../utils/misc/duration.js';

const VideoPlayer = (props) => {

  const { url, autoplay, muted, poster, title, subtitle, noControls } = props;
  const [isPlaying, setIsPlaying] = useState(autoplay === true ? true : false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [videoUiIsHidden, setVideoUiIsHidden] = useState(false);
  const videoPlayerMiniInactivityTimeout = useRef();
  const [aspectRatio, setAspectRatio] = useState(1 / 0.5625);
  const [posterSrc, setPosterSrc] = useState('');

  const player = useRef();

  const getCurrentTime = (e) => {
    const timeElapsed = e.playedSeconds;
    setCurrentTime(timeElapsed);
  }

  useEffect(() => {
    if (poster) {
      if (poster.path) {
        getImage(
          poster.path,
          512,
          512,
          30,
          [],
          (src) => {
            if (player.current) {
              setPosterSrc(src);
            }
          }
        )
      }
    }
  }, [poster]);

  const getCurrentTimeThrottled = _.throttle(getCurrentTime, 120);

  const getDuration = (e) => {
    const durationInSeconds = e;
    setDuration(durationInSeconds);
  }

  const handleSeek = (e) => {
    player.current.seekTo(e.target.value);
  }

  const handleSeekThrottled = _.throttle(handleSeek, 120);

  const onInactivityTimeoutCompleted = () => {
    setVideoUiIsHidden(true);
  }

  const handleInteract = () => {
    clearTimeout(videoPlayerMiniInactivityTimeout.current);
    setVideoUiIsHidden(false);
    videoPlayerMiniInactivityTimeout.current = setTimeout(onInactivityTimeoutCompleted, 3000);
  }

  const handleFullScreen = () => {
    if (player.current?.getInternalPlayer()) {
      if (player.current?.getInternalPlayer().requestFullscreen) {
        player.current?.getInternalPlayer().requestFullscreen();
      } else if (player.current?.getInternalPlayer().webkitRequestFullscreen) { /* Safari */
        player.current?.getInternalPlayer().webkitRequestFullscreen();
      } else if (player.current?.getInternalPlayer().msRequestFullscreen) { /* IE11 */
        player.current?.getInternalPlayer().msRequestFullscreen();
      }
    }
  }

  return (
    <div
      className="video-player"
      onClick={handleInteract}
      onTouchStart={handleInteract}
      onMouseMove={handleInteract}
      style={{
        paddingBottom: 100 / aspectRatio + '%'
      }}
    >
      <AnimatePresence>
        {
          posterSrc !== '' && isPlaying === false &&
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: .4,
              delay: isPlaying === true ? .4 : 0
            }}
            className="video-player__poster">
            <div
              className="video-player__poster__img"
              alt={poster.title}
              style={{
                backgroundImage: `url(${posterSrc})`
              }}
            />
          </motion.div>
        }
      </AnimatePresence>
      <div className="video__wrapper">
        <button
          className="button--fullscreen"
          aria-label="view fullscreen"
          onClick={handleFullScreen}
        >
          <FullscreenIcon />
        </button>
        <ReactPlayer
          ref={player}
          url={url}
          className="video-player__player"
          playsinline={true}
          playing={isPlaying}
          volume={1}
          muted={muted ? muted : false}
          height={'100%'}
          width={'100%'}
          loop={true}
          onCanPlay={(e) => {
            if (url.indexOf('storage') === 0) {
              if (player.current.getInternalPlayer() && player.current.getInternalPlayer().videoWidth) {
                setAspectRatio(player.current.getInternalPlayer().videoWidth / player.current.getInternalPlayer().videoHeight);
              }
            }
          }}
          onPlay={() => { setIsPlaying(true) }}
          onPause={() => { setIsPlaying(false) }}
          onProgress={(e) => {
            getCurrentTimeThrottled(e)
          }}
          onSeek={(e) => {
            getCurrentTimeThrottled(e)
          }}
          onDuration={(e) => {
            getDuration(e)
          }}
        />
      </div>
      {
        noControls !== true &&
        <div className={`video-player__controls`}>
          <AnimatePresence>
            {
              (videoUiIsHidden === false || isPlaying === false) &&
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: .2 }}
                  className="video-player__controls__inner"
                >
                  <div className="video-player__time__wrapper">
                    <p className="video-player__time video-player__time--elapsed">
                      {durationInSecondsGetHours(currentTime)} : {durationInSecondsGetMinutes(currentTime)} : {durationInSecondsGetSeconds(currentTime)}
                    </p>
                    <button
                      className={`video-player__button--play-pause ${isPlaying === false ? 'play' : 'pause'}`}
                      onClick={() => {setIsPlaying(!isPlaying)}}
                    />
                    <div className="video-player__input--time__wrapper">
                      <input
                        type="range"
                        className="video-player__input--time"
                        name="time"
                        max={duration}
                        value={currentTime}
                        onChange={(e) => {
                          setIsPlaying(false);
                          setCurrentTime(e.target.value);
                          handleSeekThrottled(e);
                        }}
                        onMouseDown={() => {
                          setIsPlaying(false);
                        }}
                        onMouseUp={() => {
                          setIsPlaying(true);
                        }}
                      />
                  </div>
                </div>
              </motion.div>
            }
          </AnimatePresence>
        </div>
      }
      <AnimatePresence>
        {
          (title || subtitle) &&
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="video-player__title-and-subtitle__wrapper"
          >
            {
              title &&
                <h2 className="video-player__title font--universalSans">
                  <span>{title}</span>
                </h2>
            }
            {
              subtitle &&
              <p className="video-player__subtitle font--gtPressuraMono">
                <span>{subtitle}</span>
              </p>
            }
          </motion.div>
        }
      </AnimatePresence>
    </div>
  )
}

export default VideoPlayer;