import Handwritten from './Handwritten';

const ThreeHandwritten = (props) => {

  return (
    <Handwritten
      viewBox="0 0 58.99 115.65"
      id="three"
      {...props}
    >
      <path d="M7.76,25.49a76.19,76.19,0,0,0,9-9.29c1.49-2,8.3-5,9.62-7s7.3-5.65,10-3.65,3,16.76,3.48,21.07-.49,10.29-1,11.12S28.51,43.91,28,45.9s17.59.17,21.08,3.32,6.47,16.1,4,23.23S38.63,105.81,8.43,110.62c-7.1,1.13-1-31.35-1-31.35"/>
    </Handwritten>

  )
}

export default ThreeHandwritten;