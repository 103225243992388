import { useState } from 'react';
import { motion } from 'framer-motion';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { FlexibleContentBlock } from '../components/FlexibleContentBlock.js';
import Handwritten from './icons/handwrittenElements/Handwritten.js';
import TransformControls from './TransformControls.js';

const BackButton = (props) => {

  const [isHovered, setIsHovered] = useState(false);
  const [textAppearanceVisible, setTextAppearanceVisible] = useState(true);

  return (
    <Link
      to="/channel-4"
      className="back-button handwritten-button uppercase"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onBlur={() => setIsHovered(false)}
    >
      <div className="handwritten-button__inner">
        <span className={`back-button__text handwritten-button__text${textAppearanceVisible === false ? ' hidden' : ''}`}>&larr;</span>
        <Handwritten
          viewBox="0 0 136.11 79.94"
          id="back"
          isHovered={isHovered}
          setTextAppearanceVisible={setTextAppearanceVisible}
          {...props}
        >
          <path d="M242.24,238.86c-54.85-.66-54.19.66-76.65,0s-56.17-.19-56.17-.19" transform="translate(-106.14 -198.56)"/>
          <path d="M139.88,198.86s-17,23.16-22.53,31.07-11.48,12.5-10.63,14.17,31.46,14.89,36.14,18.2,14.88,13.21,17.43,15.86" transform="translate(-106.14 -198.56)"/>
        </Handwritten>
      </div>
    </Link>
  )
}

const StrategyItemContent = (props) => {
  const { strategyItem, location, path, channel3 } = props;
  const { pathname } = location;
  const { content, title, subtitle, intro, scribble, scribbleWidth, scribbleHeight } = strategyItem;

  return (
    <div className={`strategy-item__content ${pathname === path ? 'active' : 'inactive'}`}>
      <div className="strategy-item__container">
        <div className="strategy-item__row">
          {
            pathname === path && channel3 !== true &&
            <BackButton {...props} />
          }
          <div className="strategy-item__column strategy-item__column--1">
            <div className="strategy-item__column--1__inner">
              <h2 className="strategy-item__heading font--universalSans uppercase">
                {
                  scribble &&
                  <div className="strategy-item__heading__scribble"
                    style={{
                      width: scribbleWidth && scribbleWidth !== '' ? scribbleWidth + '%' : null,
                      height: scribbleHeight && scribbleHeight !== '' ? scribbleHeight + '%' : null
                    }}
                  >
                    {parse(scribble)}
                  </div>
                }
                <span className="strategy-item__heading__text">{title}</span>
              </h2>
              <h3 className="strategy-item__subtitle font--gtPressuraMono uppercase">{subtitle}</h3>
              {
                pathname === path &&
                <div className="strategy-item__intro">
                  <TransformControls />
                  <div className="strategy-item__intro__inner">
                    {intro ? parse(intro) : ''}
                  </div>
                </div>
              }
            </div>
          </div>
          {
            pathname === path &&
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="strategy-item__column strategy-item__column--2"
            >
              <div className="strategy-item__content--main">
                <div className="strategy-item__content--main__rows">
                  {
                    content ?
                      content[0] ?
                        content.map(
                          (item, i) => (
                            <FlexibleContentBlock {...item} {...props} key={i} />
                          )
                        )
                        : '' : ''
                  }
                </div>
              </div>
            </motion.div>
          }
        </div>
      </div>
    </div>
  )
}

const StrategyItem = (props) => {

  const { strategyItem, channel3 } = props;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.3
      }}
      className={`view--strategy-item${channel3 === true ? ' channel-3' : ''}`}
    >
      {
        strategyItem.content ?
          <StrategyItemContent
            strategyItem={strategyItem}
            {...props}
          /> : ''
      }
    </motion.div>
  );
}

export default StrategyItem;