import React, { useState, useEffect, Fragment } from 'react';
import {
  Route,
  Routes,
  useNavigate,
  useLocation
} from 'react-router-dom';
import '../css/index.css';
import { getCookieConsentValue } from 'react-cookie-consent';
import Header from './components/Header';
import LandingPage from './views/LandingPage';
import VideoChannel from './views/VideoChannel';
import StrategyItem from './components/StrategyItem';
import Channel4 from './views/Channel4';
import HomeMenu from './views/HomeMenu';
import TheSmallPrint from './views/TheSmallPrint';

import { fetchCockpitData } from './utils/cockpit.js';
import { AnimatePresence, useReducedMotion } from 'framer-motion';
import Menu from './components/Menu';
import CookiesNotice from './components/CookiesNotice';
import Grid from './components/Grid';

const Content = (props) => {

  const { windowWidth, windowHeight, location, channel1Data, channel2Data, channel3Data, channel5Data, smallPrintData, menuIsActive, hoveredHeaderItem } = props;

  useEffect(() => {
    const year = new Date().getFullYear();
    document.title = `Selfridges Open House ${year}`;
  }, []);

  return (
    <div
      className={`app`}
      style={{
        height: windowHeight + 'px'
      }}
    >
      <Header {...props} />
      <div className={`content__wrapper${hoveredHeaderItem.text ? ' header-item-is-hovered' : ''}`}>
        <div className="content__inner checkerboard">
          <AnimatePresence exitBeforeEnter initial={false}>
            <Routes location={location} key={location.pathname.indexOf('channel-4') > -1 ? 'channel-4' : location.pathname}>
              <Route path="/" element={<LandingPage {...props} />} />
              <Route path="/channel-1" element={<VideoChannel {...props} channel={channel1Data} />} />
              <Route path="/channel-2" element={<VideoChannel {...props} channel={channel2Data} />} />
              <Route path="/channel-3" element={
                <Fragment>
                  <Grid />
                  <StrategyItem {...props} strategyItem={channel3Data} path={'/channel-3'} channel3={true} />
                </Fragment>} />
              <Route path="/channel-4/*" element={<Channel4 {...props} />} />
              <Route path="/channel-5" element={<VideoChannel {...props} channel={channel5Data} />} />
              <Route path="/channel-8" element={<TheSmallPrint {...props} channel={smallPrintData} />} />
              <Route path="/home" element={<HomeMenu {...props} />} />
            </Routes>
          </AnimatePresence>
          <AnimatePresence>
            {
              (windowWidth <= windowHeight || windowWidth < 768) &&
              menuIsActive === true &&
              <Menu {...props} key="menu--mobile" />
            }
            {
              props.cookiesAreAccepted === false &&
              <CookiesNotice {...props} key="cookies-notice" />
            }
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}

const App = (props) => {

  const [windowDimensions, setWindowDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [hoveredHeaderItem, sethoveredHeaderItem] = useState({});
  const [landingPageData, setLandingPageData] = useState({});
  const [channel1Data, setChannel1Data] = useState({});
  const [channel2Data, setChannel2Data] = useState({});
  const [channel3Data, setChannel3Data] = useState({});
  const [channel5Data, setChannel5Data] = useState({});
  const [strategyData, setStrategyData] = useState({});
  const [strategyItemsData, setStrategyItemsData] = useState([]);
  const [theSmallPrintData, setTheSmallPrintData] = useState({});
  const [cookiesAreAccepted, setCookiesAreAccepted] = useState(getCookieConsentValue() ? true : false);
  const motionIsReduced = useReducedMotion();
  const [menuIsActive, setMenuIsActive] = useState(false);
  const [email, setEmail] = useState('');
  const [isMuted, setIsMuted] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowDimensions({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth
      });
    }
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    fetchCockpitData('landing_page', setLandingPageData, false);
    const handleFetchEmail = (response) => {
      setEmail(response.email);
    }
    fetchCockpitData('email', handleFetchEmail, false);
    fetchCockpitData('channel1', setChannel1Data, false);
    fetchCockpitData('channel2', setChannel2Data, false);
    fetchCockpitData('channel3', setChannel3Data, false);
    fetchCockpitData('Strategy', setStrategyData, false);
    fetchCockpitData('strategy', setStrategyItemsData, true);
    fetchCockpitData('channel5', setChannel5Data, false);
    fetchCockpitData('the_small_print', setTheSmallPrintData, false);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  return (
    <Content
      {...props}
      navigate={navigate}
      location={location}
      windowDimensions={windowDimensions}
      windowWidth={windowDimensions.windowWidth}
      windowHeight={windowDimensions.windowHeight}
      landingPageData={landingPageData}
      channel1Data={channel1Data}
      channel2Data={channel2Data}
      channel3Data={channel3Data}
      strategyData={strategyData}
      channel5Data={channel5Data}
      strategyItemsData={strategyItemsData}
      motionIsReduced={motionIsReduced}
      menuIsActive={menuIsActive}
      setMenuIsActive={setMenuIsActive}
      theSmallPrintData={theSmallPrintData}
      email={email}
      cookiesAreAccepted={cookiesAreAccepted}
      setCookiesAreAccepted={setCookiesAreAccepted}
      hoveredHeaderItem={hoveredHeaderItem}
      sethoveredHeaderItem={sethoveredHeaderItem}
      isMuted={isMuted}
      setIsMuted={setIsMuted}
    />
)};

export default App;
