import { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import TransformControls from '../components/TransformControls';
import Channel4LinkCanvas from '../components/Channel4LinkCanvas';
import StrategyItem from '../components/StrategyItem';
import Grid from '../components/Grid';
import scribbleSound1 from '../../assets/sounds/scribble-1.mp3';
import scribbleSound2 from '../../assets/sounds/scribble-2.mp3';
import scribbleSound3 from '../../assets/sounds/scribble-3.mp3';
import scribbleSound4 from '../../assets/sounds/scribble-4.mp3';

const Channel4Link = (props) => {

  const { path, strategyItem, index, location, hoveredChannel, handleMouseEnter, handleMouseLeave, menu, isMuted } = props;
  const { pathname } = location;
  const audioArray = useRef([]);
  const timeout = useRef();
  const item = useRef();

  const stopAudio = () => {
    clearTimeout(timeout.current);
    if (audioArray.current[0]) {
      for (let i = 0; i < audioArray.current.length; i++) {
        audioArray.current[i].pause();
      }
    }
    audioArray.current = [];
  }

  const playAudio = () => {
    if (isMuted !== true && pathname === '/channel-4') { //
      const element = item.current;
      const svg = element.querySelector('svg');

      stopAudio();

      if (svg) {
        const sounds = [scribbleSound1, scribbleSound2, scribbleSound3, scribbleSound4];
        const paths = svg.querySelectorAll('path').length;
        let count = 0;

        const playScribble = () => {
          const randomSrc = sounds[Math.floor(Math.random() * sounds.length)];

          const audio = document.createElement('audio');
          audio.src = randomSrc;
          audio.volume = 0.5;
          audio.play().catch(error => console.log(error));
          audioArray.current.push(audio);
          count++;
          if (count < paths) {
            timeout.current = setTimeout(playScribble, 600);
          }
        }

        playScribble();
      }
    }
  }

  return (
    <li
      ref={item}
      onMouseEnter={playAudio}
      onMouseLeave={stopAudio}
      onBlur={stopAudio}
      className={`menu--channel-4__item${pathname === path ? ' active' : ''}${pathname !== '/channel-4' && pathname !== path ? ' inactive' : ''}${hoveredChannel === index && pathname !== path ? ' hovered' : ''}`}
    >
      <div
        className="menu--channel-4__item__inner"
        onMouseEnter={() => handleMouseEnter(index)}
        onMouseLeave={() => handleMouseLeave(index)}
      >
        <AnimatePresence>
          {
            pathname === path &&
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key="grid"
              style={{ position: 'absolute', width: '100%', height: '100%', zIndex: 1 }}
            >
              <Grid />
            </motion.div>
          }
          {
            (pathname === path || pathname === '/channel-4') &&
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="menu--channel-4__item__canvas__wrapper"
            >
              <Channel4LinkCanvas {...strategyItem} isActive={pathname === path} menu={menu} {...props} />
            </motion.div>
          }
        </AnimatePresence>
        <AnimatePresence>
          <StrategyItem {...props} hoveredChannel={hoveredChannel} />
          {
            pathname === '/channel-4' &&
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key="link"
              className="menu--channel-4__item__link__wrapper"
            >
              <TransformControls />
              <Link
                to={path}
                className="menu--channel-4__item__link"
              >
              </Link>
            </motion.div>
          }
        </AnimatePresence>
      </div>
    </li>
  )
}

const Channel4 = (props) => {

  const { strategyItemsData } = props;
  const [hoveredChannel, setHoveredChannel] = useState(-1);
  const menu = useRef();

  useEffect(() => {
    setHoveredChannel(-1);
  }, [])

  const handleMouseEnter = (index) => {
    setHoveredChannel(index);
  }

  const handleMouseLeave = (index) => {
    if (hoveredChannel === index) {
      setHoveredChannel(-1);
    }
  }

  return (
    <div className="menu--channel-4__wrapper">
      <ul
        ref={menu}
        className="menu--channel-4"
      >
        {
          strategyItemsData.entries &&
          strategyItemsData.entries[0] &&
          strategyItemsData.entries.map(
            (strategyItem, i) => (
              <Channel4Link
                path={`/channel-4/${i + 1}`}
                index={i}
                key={i}
                strategyItem={strategyItem}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                hoveredChannel={hoveredChannel}
                {...props}
                menu={menu}
              />
            )
          )
        }
      </ul>
    </div>
  );
}

export default Channel4;