import { Link } from "react-router-dom";
import { cockpitUrl } from '../utils/cockpit';

const Menu = (props) => {

  const { channel1Data, channel2Data, channel3Data, strategyData, channel5Data } = props;

  return (
    <nav className="menu--mobile">
      <div className="menu--mobile__inner">
        <div className="menu--mobile__grid">
          {
            Array(4).fill('').map(
              (item, i) => (
                <div key={i} className="grid__line grid__line--horizontal menu--mobile__grid__line menu--mobile__grid__line--horizontal" />
              )
            )
          }
          <div className="grid__line grid__line--vertical menu--mobile__grid__line menu--mobile__grid__line--vertical" />
          <div className="grid__line grid__line--vertical menu--mobile__grid__line menu--mobile__grid__line--vertical" />
        </div>
        <Link
          to="/channel-1"
          className="menu--mobile__link"
          onClick={() => props.setMenuIsActive(false)}
        >{channel1Data.title}</Link>
        <Link
          to="/channel-2"
          className="menu--mobile__link"
          onClick={() => props.setMenuIsActive(false)}
        >{channel2Data.title}</Link>
        <Link
          to="/channel-3"
          className="menu--mobile__link"
          onClick={() => props.setMenuIsActive(false)}
        >{channel3Data.title}</Link>
        <Link
          to="/channel-4"
          className="menu--mobile__link"
          onClick={() => props.setMenuIsActive(false)}
        >{strategyData.title}</Link>
        <Link
          to="/channel-5"
          className="menu--mobile__link"
          onClick={() => props.setMenuIsActive(false)}
        >{channel5Data.title}</Link>
        <a
          rel="noreferrer"
          target="_blank"
          href={`mailto:${props.email}`}
          className="menu--mobile__link"
          onClick={() => props.setMenuIsActive(false)}
        >Share your thoughts</a>
        <a
          href={strategyData.strategy_download_link ? cockpitUrl + '/' + strategyData.strategy_download_link.replace('cockpit/', '') : ''}
          download={true}
          rel="noreferrer"
          target="_blank"
          className="menu--mobile__link"
          onClick={() => props.setMenuIsActive(false)}
        >Download our strategy</a>
        <Link
          to="/channel-8"
          className="menu--mobile__link"
          onClick={() => props.setMenuIsActive(false)}
        >The Small Print</Link>
      </div>
    </nav>
  )
}

export default Menu;