import Handwritten from './Handwritten';

const TwoHandwritten = (props) => {

  return (
    <Handwritten
      viewBox="0 0 76.21 112.55"
      id="two"
      {...props}
    >
      <path d="M5.72,49.36s4.26-7,4.57-10,0-12.73,3.73-19.25S29.24,3.61,33.89,5.16,50,59.18,50,62s-39.26,19.56-44.31,23,18,9.93,22.15,11.18,24.64,13.34,31.78,11.17,10.7-8.45,11.56-10"/>
    </Handwritten>

  )
}

export default TwoHandwritten;