import { useState, useEffect } from 'react';
import { getImage } from '../utils/cockpit';

const LazyloadImage = (props) => {

  const { image, maxWidth, maxHeight, isBackgroundImage, isLocal, alt } = props;
  const [imageSrcSmall, setImageSrcSmall] = useState('');
  const [imageSrcLarge, setImageSrcLarge] = useState('');
  const [imageIsLoaded, setImageIsLoaded] = useState(false);

  useEffect(() => {

    let img;
    const onImageLoad = () => {
      setImageIsLoaded(true);
    }

    if (isLocal === true) {
      setImageSrcLarge(image);
      img = document.createElement('img');
      img.addEventListener('load', onImageLoad);
      img.src = image;
    } else {
      if (image.path) {
        const path = image.path;

        getImage(path, 16, 16, 30, [], (imageUrlSmall) => {
          setImageSrcSmall(imageUrlSmall);
          getImage(path,
            maxWidth ? maxWidth : 2500,
            maxHeight ? maxHeight : 2500,
            30,
            [],
            (imageUrl) => {
              const url = imageUrl;
              setImageSrcLarge(url);
              img = document.createElement('img');
              img.addEventListener('load', onImageLoad);
              img.src = url;
            }
          )
        });
      }
    }

    return () => {
      if (img) {
        img.removeEventListener('load', onImageLoad);
      }
    }
  }, [maxWidth, maxHeight, image, isLocal]);

  if ((image.path && image.path !== '') || (image && isLocal === true)) {
    return (
      <img
        className={`image--lazyload${imageIsLoaded === true ? ' loaded' : ''}${isBackgroundImage === true ? ' background-image--lazyload' : ''}`}
        src={imageIsLoaded === true ? imageSrcLarge : imageSrcSmall}
        alt={isLocal === true ? alt : image.title}
      />
    );
  } else {
    return null
  }
}

export default LazyloadImage;