const cockpitUrl = "https://cms.openhouse.selfridges.com/cockpit";

const getImage = (path, width, height, quality, filters_array, callback) => {
  fetch(`${cockpitUrl}/api/cockpit/image?token=${process.env.REACT_APP_API_KEY}`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      src: path,
      m: ('bestFit'),
      f: filters_array,
      // f: (array),         // filter name(s), one or more of: 'blur' | 'brighten' | 'colorize' | 'contrast' | 'darken' | 'desaturate' | 'edge detect' | 'emboss' | 'flip' | 'invert' | 'opacity' | 'pixelate' | 'sepia' | 'sharpen' | 'sketch'
      w: (width),           // width
      h: (height),           // height
      q: (quality),           // quality
      d: true        // include full domain path
      // b64: (boolean)      // return base64 encoded image string
    })
  })
  .then(imageUrl => imageUrl.text())
  .then(imageUrl => {
    if (callback) {
      callback(imageUrl);
    }
  });
}

const fetchCockpitData = (singletonName, setDataFunction, isCollection) => {
  const apiKey = process.env.REACT_APP_API_KEY;
  fetch(`${cockpitUrl}/api/${isCollection === true ? 'collections' : 'singletons'}/get/${singletonName}?token=${apiKey}`)
  .then(response => response.json())
  .then(response => {
    setDataFunction(response)
  })
  .catch(error => {
    console.log(error);
  })
}

export { fetchCockpitData, cockpitUrl, getImage };