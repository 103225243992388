import { useRef, useEffect } from 'react';
import scribbleSound1 from '../../../../assets/sounds/scribble-1.mp3';
import scribbleSound2 from '../../../../assets/sounds/scribble-2.mp3';
import scribbleSound3 from '../../../../assets/sounds/scribble-3.mp3';
import scribbleSound4 from '../../../../assets/sounds/scribble-4.mp3';

const Handwritten = (props) => {

  const { isHovered, setTextAppearanceVisible, id, viewBox, children, isMuted } = props;

  const svg = useRef();
  const pathIndex = useRef(0);

  useEffect(() => {
    let timeout;

    const paths = svg.current.children;
    const audioArray = [];

    const playAudio = () => {
      if (isMuted !== true) {
        const sounds = [scribbleSound1, scribbleSound2, scribbleSound3, scribbleSound4];
        const randomSrc = sounds[Math.floor(Math.random() * sounds.length)];

        const audio = document.createElement('audio');
        audio.src = randomSrc;
        audio.volume = 0.5;
        audioArray.push(audio);
        audio.play().catch(error => console.log(error));
      }
    }

    const incrementPathIndexHovered = () => {
      if (pathIndex.current < paths.length) {
        if (paths[pathIndex.current]) {
          paths[pathIndex.current].classList.add('active');
          timeout = setTimeout(incrementPathIndexHovered, 120);
          setTextAppearanceVisible(false);
          pathIndex.current++;
          playAudio();
        }
      }
    }

    const incrementPathIndexNotHovered = () => {
      if (pathIndex.current > 0) {
        pathIndex.current--;
        if (paths[pathIndex.current]) {
          paths[pathIndex.current].classList.remove('active');
          timeout = setTimeout(incrementPathIndexNotHovered, 120);
        }
      } else {
        setTextAppearanceVisible(true);
      }
    }

    if (paths[0]) {
      if (isHovered === true) {
        timeout = setTimeout(incrementPathIndexHovered, 200);
      } else {
        timeout = setTimeout(incrementPathIndexNotHovered, 200);
      }
    }

    return () => {
      clearTimeout(timeout);
      if (audioArray) {
        if (audioArray[0]) {
          for (let item of audioArray) {
            item.pause();
          }
        }
      }
    }
  }, [isHovered, setTextAppearanceVisible, isMuted]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      className={`handwritten handwritten--${id}`}
      ref={svg}
    >{children}</svg>
  )
}

export default Handwritten;