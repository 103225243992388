import { useRef } from 'react';
import VideoPlayerFullScreen from "../components/VideoPlayerFullScreen";

const VideoChannel = (props) => {

  const { channel } = props;
  const pageInner = useRef();
  const wrapper = useRef();

  console.log(channel)

  return (
    <div
      className="page"
    >
      <div
        className="page__inner no-border"
        ref={pageInner}
      >
        <div
          className="page__video--full__wrapper"
          ref={wrapper}
        >
          {
            channel.videoEmbed && channel.videoEmbed !== '' &&
            <VideoPlayerFullScreen
              url={channel.videoEmbed}
              title={channel.title}
              titleWithLineBreaks={channel.titleWithLineBreaks}
              subtitle={channel.subtitle}
              wrapper={wrapper}
              pageInner={pageInner}
              poster={channel.videoPoster}
              {...props}
            />
          }
        </div>
      </div>
    </div>
  );
}

export default VideoChannel;