import Handwritten from './Handwritten';

const MuteHandwritten = (props) => {

  return (
    <Handwritten
      viewBox="0 0 295.35 131.64"
      id="mute"
      {...props}
    >
      <path d="M5.25,127.64s.34-11.06,0-18.16S4,93.55,4,87.18c0-12,1.81-14.62,1.4-27-.13-4.12-1-15.95-.71-22.7.58-16.24,8.79-24.76,8.79-24.76a100.63,100.63,0,0,0,7.21,22.42c2.94,6.3,5.81,11.59,8.18,16.3,1.53,3,4.64,12.55,8.07,16.09s7.53-6.76,9.68-15.32c2.32-9.21,1.1-16.71,1.8-23.79s1.76-17.25,8-23.58,15.9,25.44,18.35,38.52,2.26,35.76,3.06,43.33S79.18,99,82.21,105c4,8,3.66,14.4,3.66,14.4" />
      <path d="M109.66,15.07c-2.8,2.45-5,11.82-4,16.25s5.81,47.73,5.81,54.38-2.34,23-1.14,27.48,27.23,4.61,34-4.25,8.53-14.86,6.51-28.89-3.37-47.79-2.69-58.13A36.69,36.69,0,0,0,144.73,4" />
      <path d="M173.74,7c13.51,0,38.82,1.38,49.13,0" />
      <path d="M195.88,116.16a24.19,24.19,0,0,1-3-12.44c.76-12.41,3-21.08,3-31.39s-.25-27.36-.58-42.75c-.34-16.25-.86-20.41-.86-20.41" />
      <path d="M248.81,7.6s10.6,11,22.65,13c3.64.59,8,1,8,1" />
      <path d="M248.81,7.6s-4,18.78-2,44.9c1.42,18.42,1.22,25.48-.39,36.39-.83,5.54-4.55,18.29-4.55,18.29s18-2.62,28.58-4.18a99.59,99.59,0,0,1,20.89-.45" />
      <path d="M248,59.08s15.35,3.46,22.42,3.36" />
    </Handwritten>
  )
}

export default MuteHandwritten;