import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import TransformControls from '../components/TransformControls';

const HomeMenuLink = (props) => {

  const { windowWidth, path, titleWithLineBreaks, subtitle, index, hoveredChannel, handleMouseEnter, handleMouseLeave } = props;

  return (
    <li
      className={`menu--home__item${hoveredChannel === index ? ' hovered' : ''}`}
      style={{
        width: windowWidth < 500 ? '100%' : `${hoveredChannel === index ? 60 : hoveredChannel !== -1 ? 40 : 50}%`
      }}
    >
      <div
        className="menu--home__item__inner"
        onMouseEnter={() => {
          handleMouseEnter(index);
        }}
        onMouseLeave={() => handleMouseLeave(index)}
      >
        <TransformControls />
        <Link
          to={path}
          className="menu--home__item__link"
        >
          <div className="menu--home__item__text__wrapper">
            <h2
              className="menu--home__item__title font--universalSans uppercase"
            >
              <span className="menu--home__item__title__span">
                {
                  titleWithLineBreaks &&
                  titleWithLineBreaks[0] &&
                  titleWithLineBreaks.map(
                    (line, i) => (
                      <span className="menu--home__item__title__span__line" key={i}>{line}</span>
                    )
                  )
                }
              </span>
            </h2>
            <AnimatePresence>
              {
                hoveredChannel === index &&
                <motion.h3
                  transition={{ style: 'linear', delay: 0.4 }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  layoutId={`subtitle${index}`}
                  className="menu--home__item__title font--gtPressuraMono uppercase"
                >{subtitle}</motion.h3>
              }
            </AnimatePresence>
          </div>
        </Link>
      </div>
    </li>
  )
}

const HomeMenu = (props) => {

  const { channel1Data, channel2Data, channel3Data, strategyData } = props;
  const [hoveredChannel, setHoveredChannel] = useState(-1);

  const handleMouseEnter = (index) => {
    setHoveredChannel(index);
  }

  const handleMouseLeave = (index) => {
    if (hoveredChannel === index) {
      setHoveredChannel(-1);
    }
  }

  return (
    <ul className="menu--home">
      <HomeMenuLink
        path="/channel-1"
        index={0}
        {...channel1Data}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
        hoveredChannel={hoveredChannel}
        {...props}
      />
      <HomeMenuLink
        path="/channel-2"
        index={1}
        {...channel2Data}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
        hoveredChannel={hoveredChannel}
        {...props}
      />
      <HomeMenuLink
        path="/channel-3"
        index={2}
        {...channel3Data}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
        hoveredChannel={hoveredChannel}
        {...props}
      />
      <HomeMenuLink
        path="/channel-4"
        index={3}
        {...strategyData}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
        hoveredChannel={hoveredChannel}
        {...props}
      />
    </ul>
  );
}

export default HomeMenu;