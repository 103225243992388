import { useEffect } from 'react';
import { motion } from 'framer-motion';
import parse from 'html-react-parser';
import Grid from '../components/Grid';

const TheSmallPrint = (props) => {

  const { theSmallPrintData } = props;

  useEffect(() => {
    document.title = "Selfridges Open House 2021 | The Small Print";
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="the-small-print-page view-outer page__content__wrapper--padded container-fluid"
    >
      <Grid />
      <div className="the-small-print__row row">
        <div className="col-xxs-12 col-xs-3 col-sm-6 the-small-print__title__wrapper">
          <h2 className="the-small-print__title uppercase">The Small Print</h2>
        </div>
        <div className="col-xxs-12 col-xs-9 col-sm-6 col-md-5 the-small-print__content__wrapper">
          <div className="the-small-print__content">
            {
              theSmallPrintData.the_small_print ? parse(theSmallPrintData.the_small_print) : ''
            }
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default TheSmallPrint;