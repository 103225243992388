import CookieConsent from 'react-cookie-consent';

const CookiesNotice = (props) => {

  const { setCookiesAreAccepted } = props;

  const handleAcceptCookies = () => {
    setCookiesAreAccepted(true);
  }

  return (
    <div
      className="cookies-notice__wrapper ui-item"
    >
      <CookieConsent
        containerClasses="cookies-notice"
        location="none"
        buttonText="I consent"
        disableButtonStyles
        hideOnAccept={false}
        disableStyles
        buttonClasses="cookies-notice__option cookies-notice__button cookies-notice__button--accept button"
        buttonWrapperClasses="cookies-notice__options"
        onAccept={handleAcceptCookies}
      >
      <div className="cookies-notice__content">
        <h2 className="cookies-notice__heading">
          Before we begin&hellip;
        </h2>
        <p className="cookies-notice__text">
          Just to let you know, this Open House experience uses cookies and similar technologies to analyse web performance and other information. Accept below or learn more about the cookies we use. <a
            className="cookies-notice__link--learn-more"
            href="/the-small-print"
            target="_blank"
            rel="noreferrer"
          >Learn more?</a>
        </p>
      </div>
      </CookieConsent>
    </div>
  )
}

export default CookiesNotice;